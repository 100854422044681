$(document).ready(function () {
    $(".datepicker").datepicker({
        format: 'dd-mm-yyyy'
    });

    var selectPickers = $('.js-Task__edit__content__header__select-picker');
    if (selectPickers.length > 0) {
        selectPickers.selectpicker({
            style: 'btn-info',
            size: 15
        });
    }

    // form select all
    var checkBoxSelectAll = $('.Table__column__checkbox__input-select--all');
    checkBoxSelectAll.on("click", function () {
        var that = $(this);
        // get parent
        var parent = that.parents('.table');
        // get parent checkbox-select-entry inputs
        var inputs = parent.find('.Table__column__checkbox__input-select--entry');
        if (parent.attr('data-status') == 0) {
            // set status to on
            parent.attr('data-status', 1);
            inputs.prop("checked", true);
        } else {
            // set status to off
            parent.attr('data-status', 0);
            inputs.prop("checked", false);
        }
    });


    // open/close buttons - angular task create modal
    var html = $('html');

    var bodyTasksIndex = $('body.Tasks--index');
    var modalOpenButton = bodyTasksIndex.find('.js-Entity_add');

    var modal = $('.Holder__modal__angular__create-task');
    var modalCloseButton = $('.Modal__angular__create-task__close');
    modal.css("display", "flex").hide();
    //html.addClass('Html__overflow-hidden');

    modalOpenButton.on("click", function () {
        html.addClass('Html__overflow-hidden');
        modal.fadeIn();
        return false;
        //event.preventDefault();
    });

    modalCloseButton.on("click", function () {
        html.removeClass('Html__overflow-hidden');
        modal.fadeOut();
        return false;
    });
});

