/**
 *
 * @param elmName
 * @param title
 * @param message
 */
function activateSimpleActionConfirm(elmName, title, message) {
    var elm = $(elmName);
    elm.on("click", function () {
        var modal = $('#Model__delete');
        if (modal.length === 0) {
            // should not happen
            return false;
        }
        // change the content of the modal
        modal.find('.Model__delete__title').text(title);
        modal.find('.Model__delete__body').text(message);
        var modelDeleteBtnConfirm = modal.find('.Model__delete__btn--confirm');
        modelDeleteBtnConfirm.text(title);
        // show modal
        modal.modal('show');
        // handle success button clicks
        modelDeleteBtnConfirm.on("click", function () {
            modal.modal('hide');
            elm.off();
            elm.trigger("click");
        });
        return false;
        //event.preventDefault();
    });
}